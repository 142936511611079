import { createContext, useContext } from "react";

interface ConfigurationFinishedContextType {
  isConfigurationFinished: boolean;
  setIsConfigurationFinished: (isConfigurationFinished: boolean) => void;
}

export const ConfigurationFinishedContext =
  createContext<ConfigurationFinishedContextType>({
    isConfigurationFinished: false,
    setIsConfigurationFinished: () => {},
  });

export function useConfigurationFinishedContext(): ConfigurationFinishedContextType {
  return useContext(ConfigurationFinishedContext);
}
