import { useConfigurationFinishedContext } from "../ConfigurationFinishedPage/ConfigurationFinishedContext";

import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { iframeId, language } from "@/Router";
import { RegioluxArrowIcon } from "@/icons/RegioluxIcons";
import { useConfigurationServicePutCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemId } from "@/openapi/api-cpq/queries/queries";
import { FloatingLoadingSpinner } from "@/tools/LoadingSpinner";

export default function SaveConfigurationButton({
  lineItemId,
}: {
  lineItemId: string | undefined;
}): JSX.Element {
  const { t } = useTranslation();

  const { setIsConfigurationFinished } = useConfigurationFinishedContext();

  const { mutate: saveCfg, isPending } =
    useConfigurationServicePutCustomApiConfigurationPublicShoppingCartConfigurationsByLineItemId();

  const cfgSessionId = window.sessionStorage.getItem("cfgSessionId");
  const userSessionId = window.sessionStorage.getItem("userSessionId");

  function saveConfiguration(): void {
    if (lineItemId && userSessionId && cfgSessionId)
      saveCfg(
        {
          lineItemId: lineItemId,
          requestBody: {
            userSessionId: userSessionId,
            configurationSessionId: cfgSessionId,
            localeId: language ? language : "de-DE",
          },
        },
        {
          onSuccess: () => {
            const postMessage = {
              iframeId: iframeId,
              eventName: "configurationComplete",
            };
            window.parent.postMessage(postMessage, "*");
            setIsConfigurationFinished(true);
          },
        },
      );
  }

  return (
    <>
      {isPending && <FloatingLoadingSpinner />}
      <Button
        disabled={!lineItemId}
        sx={{ marginTop: "1rem" }}
        onClick={saveConfiguration}
        endIcon={
          <RegioluxArrowIcon
            type="short"
            direction="right"
            style={{ fontSize: "0.8rem" }}
          />
        }
      >
        {t("regiolux:saveConfiguration")}
      </Button>
    </>
  );
}
