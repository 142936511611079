import { ReactNode } from "react";

import { Card, FormControl } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  AttributeControlProps,
  ThumbnailActionArea,
  ThumbnailValueComponentsProvider,
  useThumbnailControlLayoutProps,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import {
  CsValueController,
  useLocalOptionSelected,
} from "@plansoft-configuration-renderer/framework";

export interface ThumbnailItemProps {
  children: ReactNode;
}

interface CustomLayoutProps {
  custom: {
    customThumbnail?: boolean;
  };
}

export function CustomThumbnail(
  props: AttributeControlProps & Partial<CustomLayoutProps>,
): JSX.Element {
  const values = useVisibleAttributeValues();

  const { gridSpacing, gridDirection, gridBreakpoints } =
    useThumbnailControlLayoutProps();

  const customThumbnail = props.custom?.customThumbnail;

  return (
    <Grid container direction={gridDirection} spacing={gridSpacing}>
      {values.map((value) => {
        return (
          <Grid
            key={value.id}
            size={gridBreakpoints}
            className={"thumbnail-card" + (customThumbnail ? " custom" : "")}
          >
            <FormControl>
              <CsValueController value={value}>
                <CustomThumbnailItem>{props.children}</CustomThumbnailItem>
              </CsValueController>
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
}

export function CustomThumbnailItem(props: ThumbnailItemProps): JSX.Element {
  // there is a isSelected value but it does not work with local values
  const isSelected = useLocalOptionSelected();

  // Note: do not change "elevation" property of Card/Paper since it interferes with "raised"
  return (
    <Card className={isSelected ? "active" : ""}>
      <ThumbnailValueComponentsProvider>
        <ThumbnailActionArea>{props.children}</ThumbnailActionArea>
      </ThumbnailValueComponentsProvider>
    </Card>
  );
}
