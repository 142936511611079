import { LoadingSpinner } from "../../../tools/LoadingSpinner";
import { fetchUserDefinedGetter } from "../../../tools/api";
import { tryParseOutputData } from "../../../tools/helper";
import { CustomAddToShoppingCartButton } from "../CustomAddToShoppingCartButton/CustomAddToShoppingCartButton";
import SaveConfigurationButton from "../SaveConfigurationButton/SaveConfigurationButton";

import Box from "@mui/material/Box";
import { useSessionId } from "@plansoft-configuration-renderer/framework";
import { useQuery } from "@tanstack/react-query";

import { BillOfMaterials } from "./BillOfMaterials";
import { BillOfMaterialsResponse } from "./types";

export function BillOfMaterialsData(): JSX.Element {
  const sessionId = useSessionId();

  const {
    data: BillOfMaterialsData,
    isFetching,
    isSuccess,
  } = useQuery<BillOfMaterialsResponse>({
    queryKey: ["getBillOfMaterial"],
    queryFn: () =>
      fetchUserDefinedGetter(
        sessionId,
        "getBillOfMaterial",
        tryParseOutputData,
      ),
  });

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {isSuccess && (
        <Box>
          <BillOfMaterials
            headers={BillOfMaterialsData.output.headers}
            rows={BillOfMaterialsData.output.rows}
            totalPrice={BillOfMaterialsData.output.totalPrice}
            description={BillOfMaterialsData.output.description}
          />
          <Box display={"flex"} gap={1} justifyContent={"space-between"}>
            {BillOfMaterialsData.context.lineItemId ? (
              <SaveConfigurationButton
                lineItemId={BillOfMaterialsData.context.lineItemId}
              />
            ) : (
              <CustomAddToShoppingCartButton />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
