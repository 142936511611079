import { useEffect } from "react";

import {
  CpqCore,
  CpqQueryClientProvider,
  OverridableComponentsKeys,
} from "@4pace-cpq/core";
import Button from "@mui/material/Button";
import { QueryClientProvider } from "@tanstack/react-query";

import { cpqPath, queryClient } from "./App";
import { ShoppingCartButton } from "./components/ShoppingBasket/ShoppingCartButton";
import { CloneItemIconButtonWrapper } from "./cpq/overrides/CloneItemIconButtonWrapper/CloneItemIconButtonWrapper";
import { CustomIndicatorConfigurableProductInLineItem } from "./cpq/overrides/CustomIndicatorConfigurableProductInLineItem/CustomIndicatorConfigurableProductInLineItem";
import { CustomItemGroupActionButtonsArea } from "./cpq/overrides/CustomItemGroupActionButtonsArea";
import { DeleteItemIconButtonWrapper } from "./cpq/overrides/DeleteRowMenuItemWrapper/DeleteRowMenuItemWrapper";
import { SubItems } from "./cpq/overrides/SubItems/SubItems";
import { SumListPrice } from "./cpq/overrides/SumListPrice/SumListPrice";
import { TotalListPrice } from "./cpq/overrides/TotalListPrice/TotalListPrice";
import { MinimalApp } from "./renderer/MinimalApp";
import { ConfigurationFinishedContextProvider } from "./renderer/controls/ConfigurationFinishedPage/ConfigurationFinishedContextProvider";
import { LoadingSpinner } from "./tools/LoadingSpinner";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const cfgSessionId = urlParams.get("cfgSessionId");
const userSessionId = urlParams.get("userSessionId");
export const language = urlParams.get("language");
export const localeID = urlParams.get("localeID");
export const iframeId = urlParams.get("iframeId");

const isInIframe = window.location !== window.parent.location;

export function Router(): JSX.Element {
  const isInCPQ = window.location.pathname.includes(cpqPath);

  useEffect(() => {
    if (cfgSessionId) {
      window.sessionStorage.setItem("cfgSessionId", cfgSessionId);
    }
    if (userSessionId) {
      window.sessionStorage.setItem("userSessionId", userSessionId);
    }
  });

  return (
    <>
      {!isInCPQ && (
        <>
          {!isInIframe && (
            <Button
              //TODO[sh]: Before go live - check if all strings are still correct
              href={
                cpqPath + "/opportunities/3c5a8589-3f9c-49f3-a249-959ea151e1ae"
                // cpqPath + "/opportunities/f4ecac78-64c2-40a5-a68c-38f605ac6ed3"
              }
            >
              Go to CPQ
            </Button>
          )}
          <QueryClientProvider client={queryClient}>
            <ConfigurationFinishedContextProvider>
              {!isInIframe && <ShoppingCartButton />}
              <MinimalApp />
            </ConfigurationFinishedContextProvider>
          </QueryClientProvider>
        </>
      )}

      {isInCPQ && (
        <>
          {!isInIframe && (
            <Button sx={{ marginLeft: "2rem" }} href="/">
              Go Back
            </Button>
          )}
          <CpqQueryClientProvider>
            <CpqCore
              showDebugTools
              basePath={cpqPath}
              languageCode={language ? language : "de-DE"}
              componentsOverride={{
                [OverridableComponentsKeys.AbsoluteProgressIndicator]:
                  LoadingSpinner,
                [OverridableComponentsKeys.CartViewFooter]:
                  CustomItemGroupActionButtonsArea,
                [OverridableComponentsKeys.IndicatorConfigurableProductInLineItem]:
                  CustomIndicatorConfigurableProductInLineItem,
                [OverridableComponentsKeys.CloneItemIconButton]:
                  CloneItemIconButtonWrapper,
                [OverridableComponentsKeys.DeleteItemIconButton]:
                  DeleteItemIconButtonWrapper,
                [OverridableComponentsKeys.SubItems]: SubItems,
                [OverridableComponentsKeys.TotalList]: TotalListPrice,
                [OverridableComponentsKeys.TotalCost]: () => <></>,
                [OverridableComponentsKeys.TotalNet]: SumListPrice,
                [OverridableComponentsKeys.Status]: () => <></>,
              }}
            />
          </CpqQueryClientProvider>
        </>
      )}
    </>
  );
}
