import { Box } from "@mui/material";
import {
  CommonErrorBoundary,
  OAuthManager,
} from "@plansoft-configuration-renderer/components";
import {
  GatewayDest,
  GatewayProvider,
} from "@plansoft-configuration-renderer/gateway";

import { language } from "@/Router";

import { CFGObjectSelect } from "./CFGObjectSelect";
import { MinimalAppContext } from "./MinimalAppContext";
import { MinimalRenderer } from "./MinimalRenderer";
import { useConfigurationFinishedContext } from "./controls/ConfigurationFinishedPage/ConfigurationFinishedContext";
import ConfigurationFinishedPage from "./controls/ConfigurationFinishedPage/ConfigurationFinishedPage";

// const cfgObjectId = window.location.hash.split("/")[1];
// export const language = window.location.hash.split("/")[2];
// const cfgSessionId = window.location.hash.split("/")[3];

export const isDevOrStagEnvironment =
  window.location.href.includes("dev") ||
  window.location.href.includes("stag") ||
  window.location.href.includes("localhost");

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const cfgObjectId = urlParams.get("cfgObjectId");
const cfgSessionId = urlParams.get("cfgSessionId");

if (isDevOrStagEnvironment) {
  console.log({ cfgObjectId, cfgSessionId });
}

export function MinimalApp(): JSX.Element {
  const { isConfigurationFinished } = useConfigurationFinishedContext();

  return isConfigurationFinished ? (
    <ConfigurationFinishedPage />
  ) : (
    <MinimalAppContext>
      <GatewayProvider>
        <CommonErrorBoundary>
          {cfgObjectId || cfgSessionId ? (
            <MinimalRenderer
              baseUrl={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
              cfgObjectId={cfgObjectId ? cfgObjectId : ""}
              sessionId={cfgSessionId ? cfgSessionId : ""}
              language={language ? language : "de-DE"}
            />
          ) : (
            <CFGObjectSelect />
          )}
        </CommonErrorBoundary>
        <OAuthManager
          basePath={import.meta.env.VITE_CONFIGURATOR_BASE_URL}
          isLocalDevelopment={import.meta.env.DEV}
        />

        <Box position="sticky" top={0}>
          <GatewayDest name="navigation" />
        </Box>
      </GatewayProvider>
    </MinimalAppContext>
  );
}
