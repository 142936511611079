import { CircularProgress } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { BoxProps } from "@mui/material/Box/Box";

import { customPalette } from "@/theme/theme";

export function LoadingSpinner(): JSX.Element {
  return (
    <Backdrop
      open={true}
      sx={{
        color: customPalette.primary.main,
        zIndex: (theme) => theme.zIndex.drawer + 10000,
        backdropFilter: "blur(5px)",
        backgroundColor: "transparent",
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export function FloatingLoadingSpinner(props: BoxProps): JSX.Element {
  return (
    <Box
      {...props}
      position="fixed"
      display="flex"
      justifyContent="center"
      alignItems="center"
      right={0}
      bottom={0}
      top={0}
      left={0}
      zIndex={9999}
    >
      <LoadingSpinner />
    </Box>
  );
}
