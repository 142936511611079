import { LabeledText } from "../LabeledText/LabeledText";

import { useTranslation } from "react-i18next";

import { FormattedStaticCurrencyNumber, VMLineItems } from "@4pace-cpq/core";

export function SumListPrice({ item }: { item: VMLineItems }): JSX.Element {
  const { t } = useTranslation();

  const currencyIdentifier = item.currency?.identifier;

  return (
    <LabeledText
      label={t("regiolux:item.property.label.totalList")}
      text={
        <FormattedStaticCurrencyNumber
          value={item.totalList}
          currencyIdentifier={currencyIdentifier}
        />
      }
    />
  );
}
