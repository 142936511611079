import { ReactNode, Suspense } from "react";

import { LinearProgress } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

interface MinimalAppContextProps {
  children: ReactNode;
}

export function MinimalAppContext(props: MinimalAppContextProps): JSX.Element {
  return (
    <Suspense fallback={<LinearProgress />}>
      <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
        <Suspense fallback={<LinearProgress />}>{props.children}</Suspense>
      </MuiLocalizationProvider>
    </Suspense>
  );
}
