import { ItemGroupsTableItem, StandardComponents } from "@4pace-cpq/core";

import { iframeId } from "@/Router";

type CloneRowMenuItemProps = {
  item: ItemGroupsTableItem;
  onSuccess?: () => void;
};

export function CloneItemIconButtonWrapper(
  props: CloneRowMenuItemProps,
): JSX.Element {
  const postMessage = {
    iframeId: iframeId,
    eventName: "updateCart",
  };

  function onSuccess(): void {
    window.parent.postMessage(postMessage, "*");
  }

  return (
    <StandardComponents.CloneItemIconButton
      item={props.item}
      onSuccess={onSuccess}
    />
  );
}
