export function tryParseOutputData<T>(data: Partial<T>): T {
  if ("output" in data && typeof data["output"] === "string") {
    try {
      const result = JSON.parse(data["output"]);
      if (result && typeof result === "object") {
        data["output"] = result;
      }
    } catch (error) {
      console.error("Error parsing output data:", error);
    }
  }
  return data as T;
}

export const contentHeightResizeObserver = new ResizeObserver(() => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const iframeId = urlParams.get("iframeId");

  const height = document.documentElement.getBoundingClientRect().height;

  const postMessage = {
    iframeId: iframeId,
    eventName: "changeIframeHeight",
    height: height + 10,
  };

  window.parent.postMessage(postMessage, "*");
});
