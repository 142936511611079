import { LabeledText } from "../LabeledText/LabeledText";

import { useTranslation } from "react-i18next";

import { FormattedStaticCurrencyNumber, VMLineItems } from "@4pace-cpq/core";

export function TotalListPrice({ item }: { item: VMLineItems }): JSX.Element {
  const { t } = useTranslation();

  const currencyIdentifier = item.currency?.identifier;

  const totalQuantity = item.quantity ?? 1;

  const totalListPrice = item.totalList ? item.totalList / totalQuantity : 0;

  return (
    <LabeledText
      label={t("CPQ:item.property.label.totalList")}
      text={
        <FormattedStaticCurrencyNumber
          value={totalListPrice}
          currencyIdentifier={currencyIdentifier}
        />
      }
    />
  );
}
