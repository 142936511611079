export default {
  regiolux: {
    showDebugDrawer: "Show Debug Drawer",
    "previous.configuration": "Previous",
    "next.configuration": "Next",
    "reset.configuration": "Reset Selection",
    "reset.subConfiguration": "Reset Group",
    "bom.header": "Bill of Materials",
    "bom.sum": "Total Items",
    "add.XAS_GERAETETRAEGER": "Add more device carriers",
    "add.XAS_FUNKTIONSMODUL": "Add functional module (Optional)",
    "add.XAS_TRAGSCHIENE": "Add support rail",
    "add.show_BOM": "Show configuration result",
    addToShoppingCart: "Add to Shopping Cart",
    saveConfiguration: "Save Configuration",
    "sidebar.header": "SRT CONFIGURATOR",
    "dialog.requestQuote.open": "Request Quote",
    "dialog.requestQuote.message": "Message",
    "dialog.requestQuote.email": "Your Email Address",
    "dialog.requestQuote.salutation": "Salutation",
    "dialog.requestQuote.firstName": "First Name",
    "dialog.requestQuote.lastName": "Last Name",
    "dialog.requestQuote.phoneNumber": "Phone Number",
    "dialog.requestQuote.optionalDetails": "optional Details",
    "dialog.requestQuote.street": "Street / No.",
    "dialog.requestQuote.postcode": "Postcode / City",
    "dialog.requestQuote.company": "Company",
    "dialog.requestQuote.position": "Position",
    "dialog.requestQuote.industry": "Industry",
    "dialog.requestQuote.country": "Country",
    "dialog.requestQuote.mobile": "Mobile",
    "dialog.requestQuote.title": "Shopping Cart",
    "dialog.requestQuote.description": "Please fill out the form",
    "dialog.requestQuote.text.cancel": "Back",
    "dialog.requestQuote.text.confirm": "Submit Quote",
    "dialog.requestQuote.on.confirm": "Submit Quote",
    "dialog.requestQuote.dataPrivacy.title": "Data Protection",
    "dialog.requestQuote.dataPrivacy.text.start":
      "With this subscription I agree to the storage and use of the data according to the",
    "dialog.requestQuote.dataPrivacy.text.end": "of Regiolux GmbH.",
    "dialog.requestQuote.dataPrivacy.link.text": "Data Protection Declaration",
    "dialog.requestQuote.dataPrivacy.link.url":
      "https://www.regiolux.de/en/data-privacy",
    "dialog.requestQuote.emailMarketingConsent.title": "Information",
    "tooltip.toConfigure": "Configure",
    "title.configurationFinished":
      "The configuration has been successfully completed.",
    "subItem.property.label.listPrice": "List price",
    "subItem.property.label.totalList": "Sum",
    "item.property.label.totalList": "Sum",
  },
};
