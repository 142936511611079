import { useTranslation } from "react-i18next";

import {
  VMConfigurations,
  VMLineItems,
  VMProductTypes,
  VMProducts,
  useLanguage,
} from "@4pace-cpq/core";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

import { iframeId } from "@/Router";
import { useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsEdit } from "@/openapi/api-cpq/queries/queries";
import { FloatingLoadingSpinner } from "@/tools/LoadingSpinner";

export function CustomIndicatorConfigurableProductInLineItem({
  lineItemId,
  productId,
  configurationId,
  productTypeIdentifier,
  cfgObjectId,
}: {
  lineItemId: VMLineItems["id"];
  productId: VMProducts["id"];
  configurationId?: VMConfigurations["id"];
  productTypeIdentifier?: VMProductTypes["identifier"];
  cfgObjectId: VMProducts["cfgObjectIdentifier"];
}): JSX.Element {
  const { t } = useTranslation();

  const { selectedLanguage } = useLanguage();

  const { mutate: editCfg, isPending } =
    useConfigurationServicePostCustomApiConfigurationPublicShoppingCartConfigurationsEdit();

  const userSessionId = window.sessionStorage.getItem("userSessionId");

  const isLocalEnvironment = window.location.href.includes("localhost");

  const isInIframe = window.location !== window.parent.location;

  function editConfiguration(): void {
    if (cfgObjectId && userSessionId) {
      editCfg(
        {
          requestBody: {
            localeId: selectedLanguage?.languageCode || "de-DE",
            externalId: productId,
            callbackUrl: import.meta.env.VITE_APP_DATA_MANAGER_URL,
            cfgObjectID: cfgObjectId,
            userSessionId: userSessionId,
            configurationId: configurationId,
            lineItemId: lineItemId,
          },
        },
        {
          onSuccess: (data) => {
            if (data.redirectUrl) {
              if (!isInIframe) {
                const postMessage = {
                  iframeId: iframeId,
                  eventName: "editConfiguration",
                  redirectUrl: data.redirectUrl,
                };
                window.parent.postMessage(postMessage, "*");
              } else {
                if (isLocalEnvironment) {
                  const queryString = data.redirectUrl.split("?")[1];
                  const urlParams = new URLSearchParams(queryString);
                  const cfgSessionId = urlParams.get("cfgSessionId");
                  const userSessionId = urlParams.get("userSessionId");
                  const language = urlParams.get("language");
                  const port = window.location.port;

                  window.location.href =
                    `http://localhost:${port}/?` +
                    `userSessionId=${userSessionId}&cfgSessionId=${cfgSessionId}&language=${language}`;
                } else {
                  window.location.href = data.redirectUrl;
                }
              }
            }
          },
        },
      );
    }
  }

  if (productTypeIdentifier !== "Configurable") {
    return <></>;
  }

  return (
    <>
      {isPending && <FloatingLoadingSpinner />}
      {cfgObjectId && userSessionId && (
        <Tooltip
          title={t("regiolux:tooltip.toConfigure")}
          sx={{ cursor: "pointer" }}
        >
          <Icon onClick={editConfiguration}>settings</Icon>
        </Tooltip>
      )}
    </>
  );
}
