import { ReactNode, useState } from "react";

import { ConfigurationFinishedContext } from "./ConfigurationFinishedContext";

export function ConfigurationFinishedContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [isConfigurationFinished, setIsConfigurationFinished] = useState(false);
  const value = { isConfigurationFinished, setIsConfigurationFinished };

  return (
    <ConfigurationFinishedContext.Provider value={value}>
      {children}
    </ConfigurationFinishedContext.Provider>
  );
}
