import { ReactNode } from "react";

import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export function LabeledText({
  label,
  text,
  sx,
}: {
  label: ReactNode;
  text: ReactNode;
  sx?: SxProps;
}): JSX.Element {
  return (
    <Box sx={sx}>
      <Typography variant="subtitle2" color="textSecondary">
        {label}
      </Typography>
      <Typography variant="body1">{text}</Typography>
    </Box>
  );
}
