import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

export default function ConfigurationFinishedPage(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minHeight: "30vh",
        textAlign: "center",
        alignContent: "center",
      }}
    >
      <Typography variant="h4">
        {t("regiolux:title.configurationFinished")}
      </Typography>
    </Box>
  );
}
